@font-face {
  font-family: "Telegraf";
  src: url("../fonts/Telegraf-Regular.woff2") format("woff2"),
    url("../fonts/Telegraf-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Telegraf";
  src: url("../fonts/Telegraf-Medium.woff2") format("woff2"),
    url("../fonts/Telegraf-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Telegraf";
  src: url("../fonts/Telegraf-SemiBold.woff2") format("woff2"),
    url("../fonts/Telegraf-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
