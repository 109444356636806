body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  background-color: var(--body-background-color, #fbf5ee);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

body,
h1,
h2,
h3,
ol,
ul,
div {
  overflow: visible;
}

ul {
  padding-inline-start: 22px;
}

a {
  text-decoration: none;
  color: inherit;
}
p {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
*:focus {
  outline: none;
}

.shareInput::selection {
  background: lightblue;
}

.shareInput::-moz-selection {
  background: lightblue;
}

.bold {
  font-weight: 600;
}

.first-child-dim-unless-hover :first-child {
  opacity: 0.5;
}
.first-child-dim-unless-hover:hover :first-child {
  opacity: 1;
}
.first-child-hide-unless-hover :first-child {
  opacity: 0;
}
.first-child-hide-unless-hover:hover :first-child {
  opacity: 1;
}
.last-child-hide-unless-hover :last-child {
  opacity: 0;
}
.last-child-dim-unless-hover :last-child {
  opacity: 0.5;
}
.last-child-dim-unless-hover:hover :last-child {
  opacity: 1;
}
.last-child-hide-unless-hover:hover :last-child {
  opacity: 1;
}

body .MuiChip-labelSmall {
  padding-left: 5.5px;
  padding-right: 5.5px;
}

/* Making the scrollbar look non-fugly, because we have multiple scrolling elements */
.subtle-scrollbar {
  scrollbar-face-color: rgba(0, 0, 0, 0.3);
  scrollbar-track-color: none;
  padding-right: 4px;
}
.subtle-scrollbar::-webkit-scrollbar {
  width: 4px;
}
.subtle-scrollbar::-webkit-scrollbar-track {
  background: none;
}
.subtle-scrollbar::-webkit-scrollbar-track:window-inactive {
  background: none;
}
.subtle-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
.subtle-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0);
}

/* SPINKIT CSS, from http://tobiasahlin.com/spinkit/ */

.sk-spinner > div {
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.sk-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
#modal-root {
  position: fixed;
  z-index: 1500;
}
